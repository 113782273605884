<template>
  <vx-card title="Survey Answer">
    <div class="vx-input-group flex">
      <vs-button
        class="mt-2 mr-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <data-table :baseUrl="this.baseUrl"></data-table>
      </div>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    DataTable,
    Datepicker,
  },
  data() {
    return {
      baseUrl: "/api/sfa/v1/survey-answer",
    };
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: "survey-answer-create",
        // params: { id: id },
      });
    },
  },
  mounted() {},
  watch: {},
};
</script>
